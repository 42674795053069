import { Link } from "react-router-dom";
import logo from "../Static/logo.svg";
import "../Static/App.css";
function Navbar() {
  return (
    <div className="header">
      <div className="container">
        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
          <div className="logo-text">CodeCarbon</div>
        </Link>
        <ul className="links">
          <Link to="mailto:support@codecarbonhq.com">
            <li>Contact Us</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
