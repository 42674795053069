import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import arrow from "../Static/arrow-down.png";
import "../Static/App.css";
import Solutions from "./Solutions";

function scrollToContent() {
  const element = document.getElementById("ctn");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

function LandingPage() {
  return (
    <>
      <div className="landing-page">
        <Navbar />
        <div className="content">
          <div className="container">
            <div className="info">
              <div className="info-text">
                <p>
                  Code Carbon develops products for Life Sciences companies. It
                  is one stop deeply integrated solution for all your
                  manufacturing and quality needs.
                </p>
              </div>
              <Link to="#" onClick={scrollToContent}>
                <div className="info-scroll">
                  <img src={arrow} alt="scroll down" />
                  <p>
                    <b>Learn More</b>
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="content2" id="ctn">
        <div className="container">
          <div className="info">
            <p>
              With over 20 years of experience, our team specializes in
              developing cutting-edge products for the Life Sciences industry.
              Utilizing advanced AI and ML technologies, our solutions integrate
              seamlessly with organizational data to deliver valuable insights
              and enhance operational efficiency.
            </p>
          </div>
          <div className="industries">
            <h3>Industries we serve</h3>
            <ul>
              <li>
                <span>01</span>
                <p>Pharmaceuticals</p>
              </li>
              <li>
                <span>02</span>
                <p>Medical Devices</p>
              </li>
              <li>
                <span>03</span>
                <p>Food</p>
              </li>
              <li>
                <span>04</span>
                <p>Cosmetics</p>
              </li>
              <li>
                <span>05</span>
                <p>Biologics</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="solutions" id="ctn2">
        <div className="container">
          <Solutions />
        </div>
      </div>
      <div className="footer">
        <p>
          Get in touch{" "}
          <Link to="mailto:support@codecarbonhq.com">
            support@codecarbonhq.com
          </Link>
        </p>
      </div>
    </>
  );
}

export default LandingPage;
