import React from "react";
import "../Static/Solutions.css"
import SolutionCard from "./SolutionCard";
import copilot from '../Static/img/co-pilot.png';
import insights from '../Static/img/insight.png';
import personalised from '../Static/img/personalised-recommendation.png';
import updates from '../Static/img/regulatory-updates.png';
import postMarket from '../Static/img/post-market.png';
import preMarket from '../Static/img/pre-market.png';
import clinical from '../Static/img/clinical-trials.png';
import recall from '../Static/img/recall.png';

const Solutions = () => {
  return (
    <section className="solutions-section">
      <h2>Solutions</h2>
      <div className="solution-container">
        <SolutionCard
          title="Co Pilot"
          description="Enhance your manufacturing processes with our Co Pilot, providing real-time assistance and optimization."
          image={copilot}
        />
        <SolutionCard
          title="Insights"
          description="Gain deep insights into your data with advanced analytics, helping you make informed decisions faster."
          image={insights}
        />
        <SolutionCard
          title="Personalised Recommendations"
          description="Receive tailored recommendations to improve efficiency and compliance in your operations."
          image={personalised}
        />
        <SolutionCard
          title="Regulatory Updates"
          description="Stay up-to-date with the latest regulatory changes, ensuring your products meet all necessary standards."
          image={updates}
        />
        <SolutionCard
          title="Post Market Surveillance"
          description="Monitor product performance and safety post-launch, ensuring continuous compliance and quality."
          image={postMarket}
        />
        <SolutionCard
          title="Pre-Market Surveillance"
          description="Conduct thorough market analysis and surveillance before product launch to ensure market readiness."
          image={preMarket}
        />
        <SolutionCard
          title="Clinical Trials"
          description="Streamline your clinical trials with comprehensive data management and real-time tracking solutions."
          image={clinical}
        />
        <SolutionCard
          title="Reduce Recalls"
          description="Minimize product recalls with proactive quality control and continuous monitoring solutions."
          image={recall}
        />
      </div>
    </section>
  );
};

export default Solutions;
