import React from 'react'

const SolutionCard = (props) => {
    return (
        <div className="solution-card">
          <img src={props.image} alt={props.title} />
          <h4>{props.title}</h4>
          <p>{props.description}</p>
        </div>
      );
}

export default SolutionCard